import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  mailForm: FormGroup;
  isSending = false;
  showMessage = false;
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.mailForm = this.formBuilder.group({
      nameCtrl: ['', Validators.required],
      emailCtrl: ['', [Validators.required, Validators.email]],
      phoneCtrl: ['', Validators.required],
      subjectCtrl: ['', Validators.required],
      messageCtrl: ['', Validators.required],
    });
  }
  onSendMessage(){
    this.isSending = true;
    this.httpClient.post<any>('https://us8t9djge7.execute-api.eu-west-3.amazonaws.com/default',{
      body: {
        from: this.mailForm.get('emailCtrl').value,
        subject: this.mailForm.get('subjectCtrl').value,
        name: this.mailForm.get('nameCtrl').value,
        telephone: this.mailForm.get('phoneCtrl').value,
        message: this.mailForm.get('messageCtrl').value
      }
    }).pipe( finalize(() => this.isSending = false)).subscribe((response) => {
      this.showMessage = true;
      this.mailForm.reset();
    });
  }

}
