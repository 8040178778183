<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-8 text-right pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+33748326752"><i class="bx bxs-phone-call"></i> +33 7 48 32 67 52</a></li>
                        <li><a href="mailto:contact@tekre.tech"><i class="bx bxs-envelope"></i> contact@tekre.tech</a></li>
                    </ul>
                </div>
            </div>

            <div *ngIf="false" class="col-lg-2 col-sm-4 text-right pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div  class="{{navbarClass}}">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/images/logo.png" alt="logo" /></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul  class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" fragment="products" class="nav-link">Nos solutions</a>
                        </li>

                        <li class="nav-item">
                            <a  routerLink="/" fragment="services" class="nav-link">Nos services</a>
                        </li>
                        <li class="nav-item"><a routerLink="/" fragment="contact" class="nav-link">Contact</a></li>
                        <li class="nav-item"><a  routerLink="/privacy-policy" class="nav-link">Politique de protection des données</a></li>
                    </ul>
                </div>

                <div *ngIf="false"  class="nav-right">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div>

                <div *ngIf="false"  class="nav-btn">
                    <a routerLink="/solutions" class="box-btn">Get Started</a>
                </div>
            </nav>
        </div>
    </div>
</div>
