<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Votre partenaire pour la transformation numérique</h1>
                            <p>Nous sommes spécialisée dans l'édition d'applications SaaS, pour optimiser la gestion de votre entreprise (Gestion commerciale, PIM, CRM ...)</p>
                            <div class="banner-btn">
                                <a href="#contact" class="box-btn">Nous Contacter</a>
                                <a *ngIf="false" routerLink="/about" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="false" class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="no-capitalize">Pourquoi choisir Tekre Technologies ?</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-friends"></i>
                                </div>

                                <a class="accordion-title active" href="javascript:void(0)"><i class="bx bx-plus"></i> Nous sommes à votre écoute</a>
                                <p class="accordion-content show">Nous sommes à votre disposition pour capturer et analyser vos besoins.
                                De façon inclusive et iterative nous implémenterons ensemble les solutions qui répondent au mieux à vos exigences.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-chip"></i>
                                </div>

                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>Vous méritez ce qui se fait de mieux</a>
                                <p class="accordion-content">Nous travaillons avec les dernières technologies pour vous fournir des logiciels de qualités.
                               Nous vous apportons la disponibilité, la fiabilité et sécurité du cloud, avec des fournisseurs majeurs comme Amazon Web Services (AWS) et Digital Ocean</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-like"></i>
                                </div>

                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>Nous sommes une équipe d'experts</a>
                                <p class="accordion-content">Nous disposons d'une solide expérience dans le développement d'applications SaaS.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="services" class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos Services</h2>
            <p> Nous vous accompagnons dans les différentes étapes de votre transformation numérique</p>
        </div>

        <div class="row">

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Développement</h3>
                        <p>Vous voulez une application adaptée à vos spécificités ? <br> N'hesitez pas à contacter car nous sommes spécialisés
                        dans le développement d'applications web et mobiles sur mesure.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/cloud.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Cloud Computing</h3>
                        <p>Vous souhaitez migrer vers les possibilités et avantages qu'offre le cloud ?
                             Vous pourrez compter sur nos conseils avisés pour definir la meilleure approche pour vous.</p>
                        <a *ngIf="false" routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>La cyber-sécurité</h3>
                        <p>Avec nos partenaires nous vous proposons de sécuriser vos données et applications. Nos intégrons pour vous
                            les IAM (Identity and Access Management) <a href="https://www.keycloak.org/" target="_blank">Keycloak</a>  et <a href="https://fr.wikipedia.org/wiki/OpenAM" target="_blank">OpenAM</a></p>
                        <a *ngIf="false" routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="products" class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Business Booster <sup class="badge badge-danger">Beta</sup></h2>
                        <p>La gamme Business booster vous offre des solutions cloud pour optimiser la gestion de votre entreprise.
                        Pour pourrez ainsi consacrer plus de temps au développement de votre business.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Production Information management (PIM)</li>
                        <li><i class="flaticon-correct"></i> Gestion des stocks (multi-magasin)</li>
                        <li><i class="flaticon-correct"></i> Gestion du fichier client</li>
                        <li><i class="flaticon-correct"></i> Gestion des ventes</li>
                        <li><i class="flaticon-correct"></i> Suivi de la trésorerie</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section id="contact"  class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Nous contacter</span>
            <h2 class="no-capitalize">Faites-nous part de votre projet ! </h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" [formGroup]="mailForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div *ngIf="showMessage" class="alert alert-success" role="alert">
                                    Nous avons pris en compte votre message, on vous recontactera dans les plus brefs délais.
                                </div>
                                <div class="form-group">
                                    <input type="text" formControlName="nameCtrl" name="name" id="name" class="form-control" required placeholder="Nom" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" formControlName="emailCtrl" name="email" id="email" class="form-control" required placeholder="Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" formControlName="phoneCtrl" name="phone_number" id="phone_number" required class="form-control" placeholder="Numéro de téléphone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" formControlName="subjectCtrl" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Objet" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" formControlName="messageCtrl" class="form-control" id="message" cols="30" rows="5" required placeholder="Votre message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" *ngIf="!isSending" (click)="onSendMessage()" [disabled]="!mailForm.valid"  class="default-btn page-btn box-btn">Envoyer</button>
                                <button  *ngIf="isSending"  class="default-btn page-btn box-btn">Envoi en cours ...</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
