import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;
const COOKIE_CONSENT = 'tekre-consent';
const COOKIE_CONSENT_EXPIRE_DAYS = 365;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    // keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    location: any;
    routerSubscription: any;
    private isAnswered = false;
    constructor(private router: Router, private ccService: NgcCookieConsentService,
                private translateService: TranslateService) {
                 this.isAnswered = this.isConsentAnswered();
    }
    isConsentAnswered(): boolean{
      return  this.getCookie(COOKIE_CONSENT) === '1' || this.getCookie(COOKIE_CONSENT) === '0' ;
    }
    ngOnInit(){
        this.recallJsFuntions();

        this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
            console.error(event);

            // you can use this.ccService.getConfig() to do stuff...
        });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
            if (event.status === 'allow'){
                this.consent(true);
            } else if (event.status === 'deny'){
                this.consent(false);
            }
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
            // you can use this.ccService.getConfig() to do stuff...
            console.warn(event);
        });


        // Support for translated cookies messages
        this.translateService.addLangs(['en', 'fr']);
        this.translateService.setDefaultLang('fr');

        const browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'fr');

        if (!this.isAnswered){
            this.translateService//
                .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
                .subscribe(data => {

                    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
                    // Override default messages with the translated ones
                    this.ccService.getConfig().content.header = data['cookie.header'];
                    this.ccService.getConfig().content.message = data['cookie.message'];
                    this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
                    this.ccService.getConfig().content.allow = data['cookie.allow'];
                    this.ccService.getConfig().content.deny = data['cookie.deny'];
                    this.ccService.getConfig().content.link = data['cookie.link'];
                    this.ccService.getConfig().content.policy = data['cookie.policy'];

                    this.ccService.destroy(); // remove previous cookie bar (with default messages)
                    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
                });
        }else {
            this.ccService.destroy(); // remove cookie bar
        }

    }
       private getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    private deleteCookie(name) {
        this.setCookie(name, '', -1);
    }

    private setCookie(name: string, value: string, expireDays: number, path: string = '') {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath: string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    private consent(isConsent: boolean) {
        this.isAnswered = true;
        if (!isConsent) {
            this.setCookie(COOKIE_CONSENT, '0', COOKIE_CONSENT_EXPIRE_DAYS);
        } else if (isConsent) {
            this.setCookie(COOKIE_CONSENT, '1', COOKIE_CONSENT_EXPIRE_DAYS);
        }
    }

    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader-content').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            $('.loader-content').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
}
