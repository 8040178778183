<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Politique de protection des données</h2>
            <ul>
                <li><a routerLink="/">Accueil </a></li>
                <li class="active">Politique de protection des données</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="privecy-area ptb-100">
    <div class="container">
        <div class="entry-content clearfix ut-initialized">

            <div id="ut-row-66b2bee969c87" data-vc-full-width="true" data-vc-full-width-init="true" class="vc_row wpb_row vc_row-fluid vc_column-gap-0 ut-row-66b2bee969c95" style="position: relative; left: -310px; box-sizing: border-box; width: 1920px; padding-left: 310px; padding-right: 310px;"><div class="wpb_column vc_column_container vc_col-sm-12 ut-first-wpb-column"><div id="ut_inner_column_66b2bee97b6fe" class="vc_column-inner "><div class="wpb_wrapper"><div class="wpb_content_element "><style type="text/css">#ut_header_66b2bee97c032 h2.section-title, #ut_header_66b2bee97c032 h2.section-title span { color:#00385d; }#ut_header_66b2bee97c032 h2.section-title { font-size:46px !important; }#ut_header_66b2bee97c032 .lead ins { font-weight:bold; }</style><header id="ut_header_66b2bee97c032" class="section-header ut-no-title-linebreak-mobile ut-no-lead-linebreak-mobile pt-style-7 header-left header-tablet-left header-mobile-left ut-first-section-title"><h2 data-title="Politique de protection des données à caractère personnel." data-responsive-font="section_title" data-responsive-font-settings="{&quot;base-font-size&quot;:&quot;global&quot;,&quot;font-size&quot;:{&quot;desktop_large&quot;:&quot;46&quot;},&quot;base-line-height&quot;:&quot;global&quot;,&quot;base-letter-spacing&quot;:&quot;global&quot;}" class="section-title ut-flowtyped" style="font-size: 46px !important;"><span>Politique de protection des données à caractère personnel.</span></h2></header></div>
                <div class="wpb_text_column wpb_content_element ">
                    <div class="wpb_wrapper">
                        <div class="elementor-element elementor-element-ef295f2 elementor-widget elementor-widget-heading" data-id="ef295f2" data-element_type="widget" data-widget_type="heading.default">
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-c4bcdb6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c4bcdb6" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3ea1db7 ot-flex-column-vertical" data-id="3ea1db7" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-cf8d8aa elementor-widget elementor-widget-text-editor" data-id="cf8d8aa" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1" data-doc-id="1221469000001352001" data-doc-type="writer">Soucieuse du respect de votre vie privée, la protection des données personnelles est l’une de nos préoccupations majeures. Aussi la présente politique a pour objectif de vous fournir une information concise et pertinente concernant les traitements de données personnelles effectués pour la mise en œuvre du site internet :&nbsp;<a href="https://www.tekre.tech">https://www.tekre.tech.</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-975fe95 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="975fe95" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a583d06 ot-flex-column-vertical" data-id="a583d06" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-4d94c6f elementor-widget elementor-widget-heading" data-id="4d94c6f" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3></h3>
                                                    <h2 class="elementor-heading-title elementor-size-default"><span style="font-size: 24pt; color: #00385d;"><strong>1. Définitions<span style="color: #f33679;">.</span></strong></span></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-63c94b1 elementor-widget elementor-widget-text-editor" data-id="63c94b1" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1"><strong>Données personnelles</strong> : toute information relative à une personne identifiée ou identifiable directement ou indirectement (ci-après, « données » ou « données personnelles »).<br>
                                                        <strong>Traitement de données personnelles</strong> : toute opération portant sur des données personnelles telles que la collecte, l’enregistrement, l’extraction, la modification, etc. (ci-après, « traitement » ou « traitement de données personnelles »<br>
                                                        <strong>Personnes concernées</strong> : toute personne physique dont les données personnelles font l’objet d’un traitement (ci-après, « vous », « vos » ou « personnes concernées »).<br>
                                                        <strong>Responsable de traitement</strong> : personne physique ou morale qui détermine la finalité et les moyens du traitement.<br>
                                                        <strong>Délégué à la protection des données</strong> : personne désignée par le responsable de traitement pour notamment contrôler le respect de la réglementation, être le point de contact pour les questions relatives aux droits des personnes concernées et coopérer avec la Commission nationale de l’informatique et des libertés (CNIL).<br>
                                                        <strong>Cookies </strong>: fichier informatique (traceur) déposé et lu notamment suite à la consultation d’un site internet, la lecture d’un courrier électronique, l’utilisation d’une application ou d’un logiciel, quel que soit le terminal utilisé.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-183a6e8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="183a6e8" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4f7446f ot-flex-column-vertical" data-id="4f7446f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-068fd9c elementor-widget elementor-widget-heading" data-id="068fd9c" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2></h2>
                                                    <h2 class="elementor-heading-title elementor-size-default"><span style="font-size: 24pt;"><strong><span style="color: #00385d;">2. Identité du <span style="color: #f33679;"> responsable</span></span> <span style="color: #f33679;"> de traitement.</span></strong></span></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-d78a797 elementor-widget elementor-widget-text-editor" data-id="d78a797" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Les données à caractère personnelles sont collectées par <strong>TEKRE TECHNOLOGIES</strong>, société à responsabilité limitée (SARL)  au capital de 2 000,00 €, immatriculée au RCS de Versailles sous le numéro B 891 743 445. <strong>TEKRE TECHNOLOGIES</strong>, responsable du traitement de données personnelle en conformité avec le RGPD ‘Règlement Général sur la Protection des Données’ Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 et la Loi Informatique et Libertés loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018 qui a modifié la Loi Informatique et Libertés du 6 janvier 1978. Pour contacter le responsable du traitement&nbsp;:</p>
                                                    <p>Par courrier à l’adresse : 10 avenue Louis Breguet – 78140 Vélizy-Villacoublay ;<br>
                                                        Par téléphone : +33 (0)7.61.62.54.64 ;<br>
                                                        Par mail : <a href="mailto:contact@tekre.tech">contact@tekre.tech</a>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-e7ee2ec elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e7ee2ec" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-174624d ot-flex-column-vertical" data-id="174624d" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-43718f6 elementor-widget elementor-widget-heading" data-id="43718f6" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2></h2>
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="font-size: 24pt;"><span style="color: #00385d;">3. Fondement et finalité des </span> <span style="color: #f33679;"> traitements de données personnelles.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-15729a8 elementor-widget elementor-widget-text-editor" data-id="15729a8" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p><strong>Catégories des données</strong> : Des coordonnées (ex : nom, prénom, numéro de téléphone, email) ; Des informations personnelles (ex : nationalité, profession) ; Des informations techniques et de localisations générées dans le cadre de l’utilisation de nos services.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1"><strong>Finalités de traitement de données personnelles</strong> : fondé sur l’intérêt légitime du responsable de traitement visant à faciliter les échanges et assurer la communication auprès du grand public. Les données personnelles vous concernant sont traitées pour les finalités suivantes :</p>
                                                    <ul>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Assurer le bon fonctionnement et l’amélioration du site internet, des services et des fonctionnalités proposés ;</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Publier du contenu à l’attention du grand public ;</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Garantir la sécurité du site internet ;</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Des services ou fonctionnalités complémentaires fondés sur l’exécution d’un contrat (ou de mesures pré contractuelles prises à la demande de la personne concernée) sont également proposés.</li>
                                                    </ul>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Le consentement des personnes concernées fonde les traitements de données personnelles mis en œuvre aux fins de :</p>
                                                    <ul>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Gérer le dépôt de cookies, sauf ceux nécessaires au fonctionnement du site (cf. partie « Les fonctionnalités des cookies utilisés sur le site internet »).</li>
                                                    </ul>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-58b6d9e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="58b6d9e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b552f8a ot-flex-column-vertical" data-id="b552f8a" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-30f4abb elementor-widget elementor-widget-heading" data-id="30f4abb" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="font-size: 24pt; color: #00385d;">4. Collecte de <span style="color: #f33679;">données personnelles.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-78e8a9f elementor-widget elementor-widget-text-editor" data-id="78e8a9f" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Conformément à la règlementation, seules les données nécessaires aux finalités précitées sont traitées. La collecte peut s’effectuer :</p>
                                                    <ul>
                                                        <li>À partir du site ;</li>
                                                        <li>Via les formulaires de contact ;</li>
                                                        <li>Via les formulaires de candidature pour les offres d’emploi.</li>
                                                    </ul>
                                                    <p>Sur les formulaires du site, vous êtes notifié clairement de la nécessité ou non de fournir vos données personnelles.</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-189df28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="189df28" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8646f8f ot-flex-column-vertical" data-id="8646f8f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-f96fb0c elementor-widget elementor-widget-heading" data-id="f96fb0c" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="font-size: 24pt; color: #00385d;">5. Durées de conservation <span style="color: #f33679;"><span style="color: #00385d;">des</span> données personnelles.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-e7a7953 elementor-widget elementor-widget-text-editor" data-id="e7a7953" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Nous veillons à ce que les périodes de conservation soient appropriées et conformes aux délais légaux. Les données collectées sont conservées uniquement pendant la durée nécessaire à la réalisation des objectifs mentionnés précédemment, généralement le temps requis pour traiter votre demande. Les données indispensables pour répondre à votre demande, améliorer le site internet et garantir un niveau de sécurité adéquat, sont conservées jusqu’à 24 mois après leur collecte. Au-delà des périodes précitées, vos données seront anonymisées à des fins statistiques ou purement supprimées.</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-00c7224 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="00c7224" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-29232c5 ot-flex-column-vertical" data-id="29232c5" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-b3dbbd3 elementor-widget elementor-widget-heading" data-id="b3dbbd3" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default"><span style="font-size: 24pt; color: #00385d;"><strong>6. <span style="color: #00385d;">Destinataires</span> <span style="color: #f33679;"><span style="color: #00385d;">des</span> données personnelles.</span></strong></span></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2a27556 elementor-widget elementor-widget-text-editor" data-id="2a27556" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Pour la réalisation des finalités, vos données sont susceptibles d’être communiquées intégralement ou en partie selon nos instructions :</p>
                                                    <ul>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Aux services internes qui, au sein de l’organisme, sont chargées de fournir les réponses demandés ;</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Aux sous-traitants en charge des prestations techniques (développements, hébergement site internet, messagerie, etc…)</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Aux tiers légalement habilités (ex : autorités de police dans le cadre des réquisitions judiciaires, autorités compétentes);</li>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Transfert des données hors Union Européenne</li>
                                                    </ul>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Les données personnelles ne font l’objet d’aucun transfert dans un pays situé hors Union Européenne et nous faisons en sorte que les relations contractuelles fassent l’objet d’un encadrement strict au sens de l’article 28 du RGPD. Les données nécessaires à l’élaboration des statistiques et de mesures d’audience sont susceptibles d’être traitées hors UE, conformément à la politique de gestion des données mise en place par le responsable de chaque éditeur.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-c595182 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c595182" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-861cfb7 ot-flex-column-vertical" data-id="861cfb7" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-e8bbeb5 elementor-widget elementor-widget-heading" data-id="e8bbeb5" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3></h3>
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="font-size: 24pt; color: #00385d;">7. Sécurité <span style="color: #f33679;"><span style="color: #00385d;">des</span> données personnelles.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-2374549 elementor-widget elementor-widget-text-editor" data-id="2374549" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p>Le Responsable de traitement s’engage à garantir la confidentialité et la sécurité des données personnelles traitées. Il veille également à l’engagement des sous-traitants pour assurer la mise en œuvre de mesures techniques et organisationnelles afin de vous garantir un niveau de sécurité approprié.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-34f48d8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="34f48d8" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a0635c8 ot-flex-column-vertical" data-id="a0635c8" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-a5b7aa6 elementor-widget elementor-widget-heading" data-id="a5b7aa6" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3></h3>
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="font-size: 24pt; color: #00385d;">8. Vos <span style="color: #f33679;">droits.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-9873db3 elementor-widget elementor-widget-text-editor" data-id="9873db3" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Conformément à la réglementation, vous pouvez accéder aux données vous concernant. Droits délivrés à partir d’un cadre légal : Règlement Général sur la Protection des données à caractère personnel (RGPD) entré en vigueur le 25 mai 2018 et par la loi du 20 juin 2018 relative à la protection des données personnelles en modification de la loi n° 78-17 du 6 janvier 1978 « Informatique &amp; libertés ».</p>
                                                    <p>L’utilisateur dispose de plusieurs droits face au traitement de ses données à caractères personnelles pour lequel il peut effectuer une demande :</p>
                                                    <ul>
                                                        <li><strong>Droit d’accès</strong> : demander au responsable de traitement un accès au traitement de ses données (formuler une demande légitime en précisant les données et les traitements auxquels l’accès est souhaité) : les données seront transmises par courrier électronique ou postal en recommandé avec accusé de réception.</li>
                                                        <li><strong>Droit de rectification</strong> : demande de rectification de données jugées comme inexactes ou erronées (formuler une demande en joignant une copie de sa carte d’identité et en précisant clairement les données à effacer ainsi que les raisons de cette demande).</li>
                                                        <li><strong>Droit d’effacement</strong> : demande d’effacement de données collectées pour des raisons légitimes (formuler une demande en joignant une copie de sa carte d’identité et en précisant clairement les données inexactes ou erronées).</li>
                                                        <li><strong>Droit de limitation</strong> : demande de limitation du traitement des données collectées (formuler une demande en joignant une copie de sa carte d’identité et en précisant clairement les raisons de la limitation).</li>
                                                        <li><strong>Droit d’opposition</strong> : au traitement en cas de changement de finalité, mauvaise utilisation… (formuler une demande en joignant une copie de sa carte d’identité et en précisant clairement la raison de l’opposition).</li>
                                                        <li><strong>Droit à la portabilité</strong> : récupérer les données personnelles collectées sur lui dans le cadre des traitements fondés sur le consentement (formuler une demande en joignant une copie de sa carte d’identité).</li>
                                                    </ul>
                                                    <p class="zw-paragraph" data-textformat="{&quot;ff&quot;:&quot;Liberation Serif,Times New Roman&quot;}" data-margin-bottom="0pt" data-line-height="1">Ces droits peuvent être exercés directement auprès du Responsable de traitement ou de son Délégué à la protection des données à l’adresse suivante :&nbsp;<a href="mailto:contact@tekre.tech">contact@tekre.tech. </a>Si vous estimez, après nous avoir contactés, que vos droits ne sont pas respectés, vous pouvez introduire une réclamation auprès d’une autorité de contrôle compétente, la CNIL pour la France.</p>
                                                </div>
                                                <p>À tout instant, l’utilisateur du site <a href="http://www.tekre.tech">www.tekre.tech</a>, à la possibilité de se rétracter en retirant ses informations personnelles.</p>
                                                <p>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-43b2ad5 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="43b2ad5" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a15f49d ot-flex-column-vertical" data-id="a15f49d" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c514d04 elementor-widget elementor-widget-heading" data-id="c514d04" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h2></h2>
                                                    <hr>
                                                    <h2 class="elementor-heading-title elementor-size-default"><strong><span style="color: #00385d; font-size: 24pt;">Gestions <span style="color: #f33679;">des cookies.</span></span></strong></h2>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-c85d415 elementor-widget elementor-widget-text-editor" data-id="c85d415" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p data-margin-bottom="0pt" data-line-height="1"><strong>Définition de la CNIL :</strong> un cookie est un petit fichier informatique, un traceur, déposé et lu par exemple lors de la consultation d’un site internet, […], quel que soit le type de terminal utilisé (ordinateur, smartphone…).</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1"><strong>Finalités</strong> :&nbsp;Lors de la navigation sur le site internet, des outils de traçage peuvent générer et déposer un ou plusieurs cookie(s) sur votre terminal. Ils peuvent notamment faciliter votre navigation (ex : mémorisation de votre identifiant), établir des statistiques et des mesures d’audience afin d’améliorer le site internet, permettre la publicité ciblée.</p>
                                                </div>
                                                <h3></h3>
                                                <h3><span style="font-size: 18pt; color: #00385d;"><strong>1. Types de cookies</strong></span></h3>
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Les <strong>cookies fonctionnels</strong> sont strictement nécessaires à la fourniture du service expressément demandé par l’utilisateur et à la navigation (ex : adaptation de l’affichage à votre terminal, choix de langue, accès à un espace personnel, etc.). De fait, ils sont dispensés du recueil de consentement.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Les <strong>cookies analytiques</strong> permettent de générer des statistiques liées l’utilisation, la fréquentation générale et la performance du site internet en vue de l’améliorer.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Les <strong>cookies « tiers » de partage</strong> de contenu sur les réseaux sociaux facilitent le partage du contenu du site internet sur les différentes plateformes sociales. Ils sont déposés via des boutons réseaux sociaux. Pour de plus amples informations sur l’utilisation de vos données, nous vous invitons à consulter les politiques de protection des données des réseaux sociaux concernés.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Les <strong>cookies « tiers » de ciblage</strong> (publicité) permettent de proposer des contenus publicitaires personnalisés en fonction de vos centres d’intérêts.</p>
                                                    <ul>
                                                        <li class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1"><strong>Liste des cookies utilisés et/ou déposés par notre site internet : </strong>Dans le cadre de la mise en œuvre du traitement relatif à la communication externe, nous sommes amenés à utiliser les cookies analytiques.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-b692ee4 elementor-widget elementor-widget-heading" data-id="b692ee4" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <p>&nbsp;</p>
                                                    <p class="elementor-heading-title elementor-size-default">Pour plus d’information, nous vous invitons à consulter la politique de gestion des données mise en place par chaque éditeur d’outils de traçage (cookies).</p>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-top-section elementor-element elementor-element-0d7f4ef elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0d7f4ef" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-default">
                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c34a58f ot-flex-column-vertical" data-id="c34a58f" data-element_type="column">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-874eb89 elementor-widget elementor-widget-heading" data-id="874eb89" data-element_type="widget" data-widget_type="heading.default">
                                                <div class="elementor-widget-container">
                                                    <h3><span style="font-size: 18pt;"><strong><span style="color: #00385d;"> 2. Processus de gestion de cookies</span></strong></span></h3>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                            <div class="elementor-element elementor-element-1d8572b elementor-widget elementor-widget-text-editor" data-id="1d8572b" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Vous pouvez accepter, refuser ou exprimer vos préférences en matière de cookies directement via le bandeau d’information et de recueil du consentement au dépôt des cookies qui s’affiche lors de votre première visite sur le site.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Ce dernier est également accessible depuis une rubrique spécifique « Gestion des cookies » disponible au bas de chaque page du présent site. Vous pourrez ainsi revenir, à tout moment, sur les choix effectués antérieurement.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Le refus du dépôt de cookies sociaux empêchera toute interaction avec les réseaux sociaux. De même, le refus du dépôt de cookies de ciblage (publicitaire) ne permettra pas la visualisation de certains contenus multimédia.</p>
                                                    <p class="zw-paragraph" data-margin-bottom="0pt" data-line-height="1">Vous pouvez également gérer les cookies (désactivation, blocage, suppression) depuis votre navigateur ou votre terminal dans le menu « Options » / « Préférences ». La procédure à suivre est généralement décrite dans la rubrique « Aide » de votre navigateur.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="elementor-element elementor-element-cf8d8aa elementor-widget elementor-widget-text-editor" data-id="cf8d8aa" data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                                <p>&nbsp;</p>
                                <p>Limite au refus : les cookies garantissant le fonctionnement du site ne peuvent être retirés.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div></div></div><style type="text/css">#ut-row-66b2bee969c87{margin-top: 60px !important;} </style><div class="resize-sensor" style="position: absolute; inset: 0px; overflow: hidden; z-index: -1; visibility: hidden;"><div class="resize-sensor-expand" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0px; top: 0px; transition: all; width: 100000px; height: 100000px;"></div></div><div class="resize-sensor-shrink" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;"><div style="position: absolute; left: 0; top: 0; transition: 0s; width: 200%; height: 200%"></div></div></div></div><div class="vc_row-full-width vc_clearfix"></div>



        </div>
        </div>
</section>
